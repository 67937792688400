import { Title } from '@solidjs/meta';
import { Heading, TextLink } from '@troon/ui';
import { Content } from '../../../components/content';

export default function TroonRewardsTermsPage() {
	return (
		<Content size="small" class="flex flex-col gap-8">
			<Title>Terms and Conditions | Troon Rewards | Troon</Title>

			<Heading as="h1">Troon Rewards Terms and Conditions</Heading>
			<Heading as="h2">Introduction</Heading>
			<p>Participation in Troon Rewards is subject to the Member abiding by these “Terms and Conditions.”</p>
			<Heading as="h2">Eligibility</Heading>
			<ul>
				<li>
					<ul>
						<li>
							An individual may apply to enroll in the Troon Rewards Program by fully and accurately completing an
							application on the Troon Rewards webpage, at a Participating Facility, or through another enrollment
							channel
						</li>
						<li>
							Participation in Troon Rewards does not constitute a membership of any kind at participating Troon
							facilities, or otherwise
						</li>
						<li>
							Troon Rewards is for individuals only, and only one (1) Troon Rewards number will be issued to each
							eligible individual member (“Member”)
						</li>
						<li>Troon Rewards accounts are non-transferable</li>
						<li>Troon Associates are not eligible to redeem points or utilize status level discounts</li>
						<li>
							Troon may deny membership in the Troon Rewards Program to any applicant in its sole discretion and without
							written notice
						</li>
					</ul>
				</li>
			</ul>

			<Heading as="h2">Earning Points</Heading>
			<ul>
				<li>
					Members will begin earning Troon Rewards points (“Points”), in accordance with the provisions hereof, as of
					the date that the Member is approved by Troon to participate in Troon Rewards. Points will not be applied to
					purchases made prior to such date
				</li>
				<li>
					Members are eligible to earn Points for the following purchases (pre-tax) at participating Troon facilities
					(each a “Participating Facility”):
					<ul>
						<li>Member’s golf fees</li>
						<li>Member’s total merchandise purchased in the golf shop</li>
						<li>
							Certain purchases made through third parties or in connection with hotel packages, including Troon Golf
							Vacations (golf and merchandise portions only). Each facility reserves the right to determine the value of
							the golf portion of such purchases. Not all third party purchases are eligible for Points
						</li>
					</ul>
				</li>
			</ul>
			<ul>
				<li>
					Troon sponsored golf instruction executed by Troon facility or Troon Academy teaching professionals, allocated
					as follows:
					<ul>
						<li>
							<strong>Group Clinic</strong>– 25 Points
						</li>
						<li>
							<strong>Private Lesson</strong>– 50 Points
						</li>
						<li>
							<strong>Private Lesson bundles </strong>will be awarded at the rate of 50 points per lesson attended.
							Points will be awarded at the time the guest checks in for each individual lesson
						</li>
						<li>
							<strong>Troon Academy Schools</strong>– 75 Points (per day)
						</li>
					</ul>
				</li>
				<li>
					Group Golf: If you plan a group golf event that includes at least 12 golf players at a Participating Facility
					you may be eligible to earn Points as a “Group Golf Leader” as follows:
					<ul>
						<li>
							Points for Group Golf will be earned by the Group Golf Leader as follows:
							<ul>
								<li>
									One-time spend of $2,500 to $4,999 USD earns the Group Golf Leader
									<strong>250 Points</strong>
								</li>
								<li>
									One-time spend of $5,000 to $9,999 USD earns the Group Golf Leader
									<strong>500 Points</strong>
								</li>
								<li>
									One-time spend of $10,000 to $14,999 USD earns the Group Golf Leader
									<strong>1,000 Points</strong>
								</li>
								<li>
									One-time spend of $15,000 USD or more earns the Group Golf Leader
									<strong>1,500 Points</strong>
								</li>
							</ul>
						</li>
						<li>The group’s one-time spend is limited to golf fees and merchandise, including rental clubs</li>
						<li>Points are awarded at the time of final group payment</li>
						<li>
							Golf groups that pay individually are not eligible for Group Golf Troon Rewards, but the group’s
							individual golfers are eligible for Points based on their individual spend
						</li>
					</ul>
				</li>
			</ul>
			<ul>
				<li>
					To receive Points, Members must present their Troon Rewards number at the time of check-in. It is the Member’s
					sole responsibility to verify that Points have been properly credited to their Troon Rewards account
				</li>
				<li>
					If a Member does not have their Troon Rewards number at the time of purchase, or if the Participating Facility
					is unable to credit Points to the Member’s account for any reason, the Member can submit a request for missing
					points within 90 days by clicking
					<TextLink href="https://www.troon.com/troon-golf-rewards-program/points-request/">here</TextLink>
				</li>
				<li>
					Members will <u>not</u> be eligible to earn Points for the following purchases:
					<ul>
						<li>Food and beverages</li>
						<li>Golf fees for persons other than member</li>
						<li>Member initiation fees, dues, annual passes or memberships</li>
						<li>Gift cards or any other pre-purchase of golf shop credit</li>
						<li>Troon Cards</li>
						<li>
							In connection with any other product or promotion offered by Troon, unless specifically designated as such
							in writing by Troon
						</li>
						<li>Pre-paid online golf reservations made through third parties</li>
						<li>Cart fees or trail fees</li>
						<li>Any purchases at non-Participating Facilities</li>
					</ul>
				</li>
				<li>Troon will not advance Points</li>
				<li>
					When using a Troon Card “2 for 1”, only the Troon Cardholder will receive the point value of the standard
					Troon Card rate
				</li>
			</ul>
			<p>
				To verify whether a purchase is subject to Points, please submit a
				<a href="https://www.troon.com/contact-us/">Contact Us Request Form</a>.
			</p>
			<ul>
				<li>Points have no cash value and Troon will not compensate or pay cash for any forfeited or unused Points</li>
				<li>Points are not redeemable for cash</li>
				<li>Points are non-transferable and may only be used by the individual Member</li>
				<li>
					In the event that a Member has more than one Troon Rewards account, the Member may combine their Points from
					such accounts upon Troon’s prior authorization, at its sole discretion
				</li>
				<li>
					Troon, at its sole discretion, has the right to cancel or reverse any Points: (1) if such points were credited
					in error, (2) for products not paid for, or (3) for purchase refunds
				</li>
			</ul>
			<p>
				<u>Points Expiration Policy</u>
				<em>. </em>If a Member’s account is inactive for eighteen (18) consecutive months, that Member will forfeit all
				accumulated points outside of the 18-month window, based on the date of the last transaction. Members can remain
				active by earning and redeeming Points. Once points are forfeited or expire, the points cannot be reinstated,
				but a Member can earn new Points unless that Member’s account has been deactivated. If a Member does not
				maintain an active status for five (5) consecutive years, the Member’s account may be deactivated.
			</p>
			<Heading as="h2">Redeeming Points</Heading>
			<ul>
				<li>
					Points may only be used toward personal rounds of golf at Participating Facilities as provided herein. They
					may not be used for any other players greens fees, or other purchases, including, but not limited to
					merchandise, food and beverage, Troon Cards, gift cards, and initiation or membership fees
				</li>
				<li>Redemption is subject to availability and may only be redeemed at Participating Facilities</li>
				<li>
					Members can use their Points towards one (1) complimentary round of golf based on the redemption
					table/schedule. Rounds of golf can also be redeemed by using a combination of Points and cash (based on the
					<a href="https://www.troon.com/troon-golf-rewards-program/troon-rewards-redemption/">redemption chart</a>
					). Points will not be earned for the redemption portion of the golf round
					<ul>
						<li>
							<em>
								Example: A complimentary round of golf at a “Tier 4” course would require 800 Points or a combination of
								400 Points and $40*)
							</em>
						</li>
						<li>
							<em>*VAT will be applied to points and points/cash redemptions in the United Arab Emirates</em>
						</li>
					</ul>
				</li>
			</ul>
			<ul>
				<li>
					<strong>
						Rounds may be played Monday – Thursday anytime or Friday – Sunday &amp; holidays after 12:00 p.m. at
						participating Troon Golf daily-fee facilities
					</strong>
					(please note: At <strong>Kapalua</strong>, rounds can be play Monday – Sunday after 11AM. In the
					<strong>UAE</strong> rounds may be played Monday – Thursday anytime or Friday – Sunday &amp; holidays after
					12:00 p.m. In <strong>Bahrain and Saudi Arabia</strong>, rounds may be played Sunday – Thursday anytime or
					Friday – Saturday &amp; holidays after 12:00 p.m.)
				</li>
				<li>
					<strong>
						When using Points for a complimentary round, tee times can be made up to 3 days in advance, and use of
						Points must be mentioned at time of booking.
					</strong>
					VAT will be applied to points and points/cash redemptions in the United Arab Emirates
				</li>
				<li>
					Points cannot be used in conjunction with groups (12 players or more), hotel/resort packages or with any other
					special offers
				</li>
				<li>
					The issuing of Points does not constitute a reservation. Members are responsible for making all reservations
					and communicating directly with a Participating Facility regarding Points
				</li>
				<li>
					<a href="https://www.troon.com/troon-golf-rewards-program/troon-rewards-redemption/">
						Click here to view full redemption options and locations
					</a>
					. (Subject to change at any time)
				</li>
				<li>
					Tee time reservations must be canceled no later than 48 hours prior to the day of play. Failure to cancel tee
					times without proper notification may result in being charged to the Member under the Participating Facility’s
					No-Show policy
				</li>
				<li>
					It is a violation of these Terms and Conditions to reserve a tee time outside the three day booking window and
					then cancel and reserve a similar tee time inside of the three day booking window in an attempt to redeem
					Rewards points
				</li>
				<li>
					Members must abide by all rules and regulations of the Participating Facility at which it redeems Points. Each
					Participating Facility has the discretion to remove a Member from its property or end a round of golf for
					failing to abide by its rules and regulations. Troon will not provide refunds or credits for Points used at a
					Participating Facility if the Member was removed from the property or their golf round was ended for any
					legitimate reason, including for violation of the Participating Facility’s rules and regulations
				</li>
				<li>Troon has no obligation to provide refunds or credits for Points used at a Participating Facility</li>
			</ul>
			<Heading as="h2">Status Levels and Discounts</Heading>
			<ul>
				<li>
					Based on the number of Points accumulated within a calendar year, Members are eligible to receive a discount
					on certain purchases for their own rounds of golf and merchandise in the golf shop at Participating
					Facilities, as follows:
				</li>
			</ul>
			<ul>
				<li>
					<ul>
						<li>
							Accumulation of 2,000 points within a calendar year <strong>(Silver Level)</strong> entitles a Member to a
							10% discount
						</li>
						<li>
							Accumulation of 4,000 points within a calendar year <strong>(Gold Level)</strong> entitles a Member to a
							15% discount
						</li>
						<li>
							Accumulation of 8,000 points within a calendar year <strong>(Platinum Level)</strong> entitles a Member to
							a 20% discount
						</li>
					</ul>
				</li>
				<li>
					Once a Member has achieved the next higher status level, the Member will automatically begin receiving the
					applicable discount on their next applicable purchase. The Member shall retain the higher status level for the
					remainder of the calendar year in which it is achieved through the end of the following calendar year
				</li>
				<li>
					Status level discounts can be applied to the Member’s personal green fees and merchandise only. It may not be
					used towards any third party’s charges
				</li>
				<li>
					It is the Member’s sole responsibility to make sure the appropriate discount is given at the time of purchase
					discounts, refunds or credits will not be issued except at the time of purchase
				</li>
				<li>
					Troon Rewards discounts may not be combined with any other discounts, group outings, member fees (initiation,
					dues, golf fees, golf car, etc.), club-centric card/annual pass rates, or special offers for products or
					services offered by Troon, including Troon Card 10% merchandise and F&amp;B discounts at participating
					locations
				</li>
				<li>
					Troon Rewards discounts
					<span dir="ltr">
						are not applicable to the purchase of a Troon Card or subscriptions to Troon Rewards Plus.
					</span>
				</li>
				<li>
					Discounts can be applied to Troon Card rates.
					<strong>
						<em>Discounts are not valid with Troon Card “2 for 1” offers or Best Rate Guarantee</em>
					</strong>
				</li>
				<li>
					Discounts will only be given at the time of purchase (whether by cash or credit). Purchases made through third
					parties will not be subject to a discount
				</li>
			</ul>
			<Heading as="h2">MEMBERSHIP COMMUNICATIONS</Heading>
			<ul>
				<li>
					Information provided by a Member will be maintained in a personal profile. All information provided by a
					Member must be valid and accurate and must be kept current by the Member
					<ul>
						<li>
							<a href="https://www.troon.com/account/login/">CLICK HERE</a> to log in and make changes to you Member
							personal profile. Name changes may require supporting legal documentation
						</li>
					</ul>
				</li>
				<li>
					The information a Member provides to Troon for use in the Troon Rewards Program is process in accordance with
					Troon’s Privacy Policy. Communication of relevant information is important to administering the Troon Rewards
					Program and providing each Member with the opportunity to maximize the benefits of Membership
				</li>
				<li>
					Members will receive communications regarding the Troon Rewards Program. Such communications will be sent to
					Member’s mailing address or email address provided in the Member’s profile. Communications delivered to the
					address on file will be deemed to have been received one (1) business day after sending it if delivered to the
					Member’s email address or five (5) business days after sending it if delivered to the mailing address
					provided. Members must keep their email and mailing addresses current. Neither Troon nor the Troon Rewards
					Program shall have any responsibility for misdirected or lost mail or any consequences thereof
				</li>
				<li>
					Troon may also send Members promotions, offers and other communications from time to time, which may include,
					without limitation, items from third parties. The items from third parties are based on the information
					provided to Troon by a Member and any additional data Troon may maintain. Members may change personal details
					and communications preferences at any time by logging in or contacting
					<a href="mailto:rewards@troon.com">rewards@troon.com</a>
				</li>
			</ul>
			<Heading as="h2">OTHER CONDITIONS OF ENROLLMENT</Heading>
			<ul>
				<li>
					<u>Cancelling or Suspending Membership Accounts</u>
					<ul>
						<li>
							<em>Cancelling by Member</em>. A Member may cancel their membership in the Troon Rewards Program at any
							time. All unredeemed Points as well as achieved member status, will be forfeited immediately and may not
							be reinstated or transferred
						</li>
						<li>
							<em>Cancelling or Suspending by Troon</em>. The Company may cancel a Member’s accumulated Points, suspend
							Troon Rewards benefits, suspend membership status, or cancel a Member’s account at any time with immediate
							effect and without written notice, for any reason and in Troon’s sole discretion including, without
							limitation, if Troon believes the Member has:
							<ul>
								<li>Acted in a manner inconsistent with applicable laws, regulations, or ordinances</li>
								<li>Failed to pay any bill when due to Troon or a Participating Facility</li>
								<li>Acted in an inappropriate, fraudulent, abusive or hostile manner</li>
								<li>
									Breached or violated any of these Terms and Conditions or Troon’s Privacy Policy or Terms of Use for
									its website
								</li>
								<li>Fraudulently claimed eligibility to earn Points or benefits</li>
								<li>
									Engaged in any misconduct or wrongdoing in connection with the Troon Rewards Program, including,
									without limitation with respect to points, discounts or other Member benefits; or
								</li>
								<li>Violated any rules and regulations of a Participating Facility</li>
							</ul>
						</li>
						<li>
							<u>Effect of Membership Cancellation or Deactivation</u>
							<ul>
								<li>
									The Points, membership status and other related benefits and services are the sole property of Troon,
									and are not the property of Members. On cancellation or deactivation of membership in the Troon
									Rewards Program for any reason, all unredeemed points, membership discounts, membership status and all
									other member benefits will be forfeited and a Member will no longer be able to participate in the
									Troon Rewards Program. Points have no cash value and Troon will not compensate or pay cash for any
									forfeited or unused Points
								</li>
								<li>
									If Troon cancels a Member’s account for any reason, the Member may not reapply for membership in the
									Troon Rewards Program except in very limited circumstances in Troon’s sole discretion, and any account
									opened in Member’s name and Points, membership status and other membership benefits earned in that
									account will be forfeited upon discovery
								</li>
								<li>
									If a Member cancels their account, or if a Member account become deactivated, the Member may reapply
									for membership in the Troon Rewards Program at a later date, but no Points, membership status, or
									other member benefits forfeited or expired will be reinstated to the Member account
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
			<ul>
				<li>
					<u>Modification of Program</u>. Except as otherwise expressly prohibited or limited by applicable laws, Troon
					has the right to change, limit, modify or cancel the Terms and Conditions at any time in its sole and absolute
					discretion, without notice, even though such changes may affect the value of Points, or the ability to obtain
					certain membership discounts. Troon may, among other things: (i) increase or decrease the number of Troon
					Points received for a purchase or required for a round of golf or membership status level; (ii) add blackout
					dates; (iii) change Troon Rewards Program benefits, conditions of participation, rules for earning, redeeming,
					retaining or forfeiting Points; or (v) change or cancel its Participating Facilities. In accumulating Points,
					Members may not rely upon the continued availability of any membership benefit
				</li>
				<li>
					<u>Termination of Troon Rewards Program</u>. Troon, in its sole discretion, reserves the right to terminate
					the Troon Rewards Program with 3 month’s advance notice to all active Troon Rewards members. At Troon’s sole
					discretion, Troon may choose to substitute a similar loyalty program for the Troon Rewards Program at any time
					immediately upon notice to active Troon Rewards members. A Member may not accumulate Points or other member
					benefits after the termination of the Troon Rewards Program. If the Troon Rewards Program is terminated, all
					unredeemed Points will be forfeited without any obligation or liability and no membership benefit will be
					honored after the conclusion of the notice period. Troon may terminate any of the Troon Rewards Program in
					whole or in party, in any jurisdiction on less than 3 months’ notice if required to do so by applicable law
				</li>
				<li>
					<u>Benefits Subject to Availability and Modification</u>. All Troon Rewards Program benefits, amenities,
					offers, awards and services are subject to availability and may be changed at any time without notice
				</li>
				<li>
					<u>Exit of Participating Facility</u>. If a participating facility exits the Troon Rewards Program for any
					reason, a Member will not earn Points for any purchases made at that facility, and Points or discounts will
					not be applied to any rounds of golf after the date on which the participating facility exited the Troon
					Rewards Program, even if the round of golf at the former participating facility was made prior to that date
				</li>
			</ul>
			<Heading as="h2">ADDITIONAL TERMS AND CONDITIONS</Heading>
			<ul>
				<li>
					<u>Monitoring Member Accounts</u>. Troon reserves the right to monitor the Troon Rewards accounts of all
					Members, at any time and without notice, for compliance with these Terms and Conditions. Troon may review all
					Member’s Points, balances, and transaction history, including, without limitation, requests to use points,
					discounts and other benefits as provided herein
				</li>
				<li>
					<u>Publication</u>. Troon, in its sole discretion, reserves the right to publish Member winners’ names and any
					other information regarding any contest associated with Troon Rewards. Member hereby grants Troon, its
					affiliates, and Participating Facilities, the nonexclusive, perpetual and irrevocable, non-transferable and
					non-sublicensable right and license to use any photographed, recorded or videotaped images of Member, or
					shared by Member via a social media account while at a Participating Facility, for use in promoting,
					marketing, selling, and otherwise commercializing Troon, its affiliates and Participating Facilities in all
					formats and media
				</li>
				<li>
					<u>No Warranties or Representations, Express or Implied</u>. Troon makes no warranties or representations,
					either expressed or implied, with respect to type, quality or fitness of goods or services provided through
					the Troon Rewards Program or Participating Facilities
				</li>
				<li>
					<u>Not Responsible for Act, Errors, or Omissions</u>. Troon is not responsible for: (a) any loss or
					misdirection of, or delay in receiving, any Member application, correspondence, redemption requests, or other
					Member benefits; (b) theft or unauthorized redemption of Points or discounts; (c) any acts or omissions of
					third parties (including, without limitation, Participating Facilities); or (d) any errors published in
					relation to the Troon Rewards Program, including, but limited to, any pricing or typographical errors, errors
					of description, errors regarding Participating Facilities, and Troon Rewards Program affiliates, errors in the
					crediting or debiting of Points from Member accounts. Troon reserves the right to correct, without notice, any
					errors
				</li>
				<li>
					<u>Interpretation of Troon Rewards Terms and Conditions</u>. All interpretations of these Terms and Conditions
					regarding the Troon Rewards Program and membership therein are at Troon’s sole discretion, and Troon’s
					decisions will be final. In the event of any discrepancy between the English version and any translated
					version of these Terms and Conditions, the English language version will govern
				</li>
				<li>
					<u>Trademarks</u>. Troon and Participating Facilities are the sole and exclusive owners or licensees of the
					trademarks, service marks, trade names, logos, and copyrighted or copyrightable materials Troon, its
					affiliates, and the Participating Facilities. Members shall never, directly or indirectly, interfere with,
					challenge, file applications for, or claim ownership of these trademarks anywhere in the world
				</li>
				<li>
					<u>Waiver</u>. Troon’s waiver of any breach of these Terms and Conditions by any Member will not constitute a
					waiver of any other prior or subsequent breach of these Terms and Conditions. Troon’s failure to insist upon
					strict compliance with these Terms and Conditions by any Member will not be deemed a waiver of any rights or
					remedies Troon may have against that or any other Member. Troon may waive compliance with these Terms and
					Conditions in its sole discretion and may run promotions from time to time that provide enhanced benefits to
					select Members
				</li>
				<li>
					<u>Limitation of Liability</u>. IN NO EVENT WILL TROON, ITS SUBSIDIARIES AND AFFILIATES, ANY PARTICIPATING
					FACILITY, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS BE LIABILT FOR ANY DIRECT,
					INDIRECT, SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTION DAMAGES OF ANY KIND, WHETHER BASED IN
					CONTRACT, TORT OR OTHERWISE, WHICH ARISE OUT OF OR ARE IN ANY WAY CONNECTED WITH THE TROON REWARDS PROGRAM,
					THESE TERMS AND CONDITIONS, OR TROON’S OPERATION OF THE TROON REWARDS PROGRAM
				</li>
				<li>
					<u>Indemnification</u>. All Members hereby agree to indemnify, defend and hold harmless Troon (its affiliates,
					officers, directors, employees, representatives and agents) for, from and against any and all claims, causes
					of action, losses, damage, liabilities or expenses (including reasonable attorney’s fees and costs) that Troon
					may suffer arising out of, caused by or in connection with such Member’s participation in the Troon Rewards
					Program
				</li>
				<li>
					<u>Choice of Law and Venue</u>. Any disputes arising out of or related to the Troon Rewards Program or these
					Terms and Conditions will be handled individually without any class action, and will be governed by, construed
					and enforced in accordance with the laws of the State of Arizona, United States, without regard to its
					conflicts of law rules. The exclusive jurisdiction for any claim or action arising out of or relating to the
					Troon Rewards Program or these Terms and Conditions may be filed only in the state or federal courts located
					in the State of Arizona, United States
				</li>
				<li>
					<u>Subject to Law</u>. Membership in the Troon Rewards Program and the earning and redeeming of Points are
					subject to all applicable local laws and regulations. Membership in the Troon Rewards Program, Member benefits
					and any promotions are offered in good faith; however, they may not be available if prohibited or restricted
					by applicable law or regulation in the United States or Member’s jurisdiction of residence. If any part of
					these Terms and Conditions is held to be unlawful or unenforceable, that part will be deemed deleted from
					these Terms and Conditions in such jurisdiction will remain in full force, subject to the Other Conditions of
					Enrollment above
				</li>
				<li>
					<u>Legal Action</u>. Nothing contained in these Terms and Conditions will limit the Troon in the exercise of
					any legal or equitable rights or remedies
				</li>
				<li>
					<u>Entire Agreement</u>. The Troon Rewards Program, together with any other terms and conditions, rules or
					regulations incorporated herein or referred to herein constitute the entire agreement between Troon and
					Members relating to the subject matter hereof, and supersede any prior understandings or agreements (whether
					oral or written) regarding the subject matter, and may not be amended or modified except in writing or by
					making such amendments or modifications available on the Troon Rewards Program website
				</li>
				<li>
					<u>Acceptance</u>. Member’s use of and participation in Troon Rewards constitutes acceptance of these Terms
					and Conditions, as well as Troon’s
					<a href="https://www.troon.com/privacy-policy">Privacy Policy</a> and Terms of Use for its website
				</li>
			</ul>
		</Content>
	);
}
